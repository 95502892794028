import 'moment-timezone';
import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import ReactMoment from 'react-moment';
import { makeStyles, useTheme, lighten } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuList from '@material-ui/core/MenuList';
import MuiLink from '@material-ui/core/Link';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import { pluck, sortBy } from 'ramda';

import { getUpcomingConferences, items } from '../utils';
import SEO from '../components/SEO';
import Nav from '../components/Nav';
import Footer from '../components/Footer';
import Notices from '../components/Notices';
import ThumbnailItem from '../components/ThumbnailItem';
import CoverItem from '../components/CoverItem';
import LiveStreamSnippet from '../components/LiveStreamSnippet';
import MemoryVerseSnippet from '../components/MemoryVerseSnippet';
import EventsOverview from '../components/EventsOverview';
import SundayLocation from '../components/SundayLocation';
import Contact from '../components/Contact';
import Deploy from '../components/Deploy';

const useStyles = makeStyles((theme) => ({
  header: {
    background:
      'linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), url(/graphics/hero.jpg?v=2)',
    backgroundSize: 'cover, cover',
    backgroundPosition: 'center, center',
    [theme.breakpoints.up('md')]: {
      backgroundSize: 'cover, cover',
      backgroundPosition: 'center, center center',
    },
    paddingBottom: theme.spacing(6),
  },
  heroText: {
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      textAlign: 'left',
    },
  },
  heroButtons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      justifyContent: 'left',
    },
  },
  heroPrimaryButton: {
    [theme.breakpoints.up('lg')]: {
      marginRight: theme.spacing(2),
    },
  },
  eventsSection: {
    backgroundColor: theme.palette.background.secondary,
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('md')]: {
      clipPath: 'polygon(0 15%, 100% 0, 100% 85%, 0 100%)',
      paddingTop: theme.spacing(24),
      paddingBottom: theme.spacing(24),
    },
  },
}));

export const query = graphql`
  query IndexContent($sunday: API_Date!) {
    api {
      notices(orderBy: "created_at") {
        edges {
          node {
            id
            severity
            title
            message
            scheduleDays
            scheduleStartTime
            scheduleEndTime
            buttonText
            buttonUrl
          }
        }
      }
      sermons(orderBy: "-date,-created_at", sermonType: "SRM", first: 4) {
        edges {
          node {
            id
            sermonType
            title
            slug
            speakers {
              edges {
                node {
                  id
                  name
                  elder
                }
              }
            }
            date
            youtubeId
          }
        }
      }
      serieses(orderBy: "-end_date", first: 3) {
        edges {
          node {
            id
            imageThumb
            title
            slug
            startDate
            endDate
          }
        }
      }
      featuredAnswer {
        slug
        title
        unfurl
      }
      articles(orderBy: "-date,-created_at", first: 3) {
        edges {
          node {
            id
            imageThumb
            title
            slug
            author {
              id
              name
            }
            date
          }
        }
      }
      conferences(orderBy: "-start_date") {
        edges {
          node {
            id
            conferenceType
            title
            slug
            startDate
            endDate
            icalUid
            series {
              title
              imageThumb
            }
          }
        }
      }
      memoryVerses(date: $sunday) {
        edges {
          node {
            id
            image
            levelAdult
            levelJunior
            levelPrimary
            levelPreschool
            text
            reference
            translation
          }
        }
      }
      upcomingEvents(calendar: "local", first: 2) {
        edges {
          node {
            id
            icalUid
            start {
              date
              dateTime
            }
            end {
              date
              dateTime
            }
            recurrence
            summary
            location
            description
          }
        }
      }
    }
  }
`;

const Page = ({ data }) => {
  const notices = items(data.api.notices);
  const sermons = items(data.api.sermons);
  const series = items(data.api.serieses);
  const { featuredAnswer } = data.api;
  const articles = items(data.api.articles);
  const upcomingLocal = items(data.api.upcomingEvents);
  const conferences = items(data.api.conferences);
  const memoryVerses = sortBy((memoryVerse) => {
    if (memoryVerse.levelAdult) {
      return 0;
    }
    if (memoryVerse.levelJunior) {
      return 1;
    }
    if (memoryVerse.levelPrimary) {
      return 2;
    }
    return 3;
  }, items(data.api.memoryVerses));

  const theme = useTheme();
  const classes = useStyles();

  const upcomingConferences = getUpcomingConferences(conferences);

  return (
    <>
      <SEO title="River of Life Christian Fellowship in Loveland, Colorado" />

      <CssBaseline />

      <Grid container>
        <Grid item xs={12}>
          <Box
            component="header"
            display="flex"
            flexDirection="column"
            className={classes.header}
          >
            <Nav />

            <Container
              maxWidth="md"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                flex: '1',
                paddingTop: theme.spacing(6),
              }}
            >
              <Box mb={4} className={classes.heroText}>
                <Typography
                  variant="h1"
                  style={{
                    fontWeight: theme.typography.fontWeightMedium,
                  }}
                >
                  River of Life Christian Fellowship is a growing body of
                  Christian believers in Loveland, Colorado.
                </Typography>
              </Box>

              <Box className={classes.heroButtons}>
                <Box mb={2} className={classes.heroPrimaryButton}>
                  <Button
                    component={Link}
                    to="/media"
                    variant="contained"
                    color="primary"
                  >
                    Watch Latest Sermons
                  </Button>
                </Box>
                <Box mb={2}>
                  <Button component={Link} to="/events" color="secondary">
                    View Events
                  </Button>
                </Box>
              </Box>

              <Notices notices={notices} />
            </Container>
          </Box>
        </Grid>
      </Grid>

      <Box
        pt={10}
        style={{ backgroundColor: theme.palette.background.default }}
      >
        <Container maxWidth="xl">
          <Grid container spacing={4}>
            <Grid item md={4} xs={12}>
              <Box mb={4}>
                <Box mb={2}>
                  <Typography variant="h4">Sermons</Typography>
                </Box>

                <MenuList>
                  {sermons.map(
                    ({ id, title, slug, speakers, date, youtubeId }) => (
                      <ThumbnailItem
                        key={id}
                        title={title}
                        subtext={pluck('name', items(speakers)).join(', ')}
                        timestamp={date}
                        image={`https://i.ytimg.com/vi/${youtubeId}/hqdefault.jpg`}
                        to={`/media/sermons/${slug}`}
                      />
                    )
                  )}
                </MenuList>

                <Button
                  component={Link}
                  to="/media"
                  color="secondary"
                  endIcon={<ChevronRightIcon />}
                >
                  See all
                </Button>
              </Box>
            </Grid>

            <Grid item md={4} xs={12}>
              <Box mb={4}>
                <Box mb={2}>
                  <Typography variant="h4">Series</Typography>
                </Box>

                <MenuList>
                  {series.map(
                    ({ id, title, startDate, endDate, imageThumb, slug }) => (
                      <Box key={id} mb={2}>
                        <CoverItem
                          narrow
                          title={title}
                          subtext={
                            <>
                              <ReactMoment format="MMMM D">
                                {startDate}
                              </ReactMoment>{' '}
                              &mdash;{' '}
                              <ReactMoment format="MMMM D, YYYY">
                                {endDate}
                              </ReactMoment>
                            </>
                          }
                          image={imageThumb}
                          to={`/media/series/${slug}`}
                        />
                      </Box>
                    )
                  )}
                </MenuList>

                <Button
                  component={Link}
                  to="/media/series"
                  color="secondary"
                  endIcon={<ChevronRightIcon />}
                >
                  See all
                </Button>
              </Box>
            </Grid>

            <Grid item md={4} xs={12}>
              <Box mb={4}>
                <LiveStreamSnippet />
              </Box>
              <Box mb={4}>
                <Typography variant="h4">Featured Question</Typography>
              </Box>
              <Box mb={4}>
                <ButtonBase
                  href={`https://cfcanswers.com/answer/${featuredAnswer.slug}`}
                >
                  <Paper
                    style={{
                      background: lighten(theme.palette.success.main, 0.9),
                    }}
                  >
                    <Box p={4}>
                      <Typography variant="h3" component="p">
                        {featuredAnswer.title}
                        <ChevronRightIcon />
                      </Typography>
                    </Box>
                  </Paper>
                </ButtonBase>
              </Box>
              <Box display="flex" alignItems="center" mb={4}>
                <Box mr={0.5}>
                  <Typography variant="body1">Follow CFC Answers:</Typography>
                </Box>
                <IconButton
                  href="https://www.facebook.com/cfcanswers"
                  color="inherit"
                >
                  <FacebookIcon />
                </IconButton>
                <IconButton
                  href="https://twitter.com/cfcanswers"
                  color="inherit"
                >
                  <TwitterIcon />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <div style={{ background: theme.palette.background.default }}>
        <Box className={classes.eventsSection}>
          <Container maxWidth="lg">
            <EventsOverview
              local={upcomingLocal}
              conferences={upcomingConferences}
            />
          </Container>
        </Box>
      </div>

      <Box
        py={10}
        style={{ backgroundColor: theme.palette.background.default }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center">
                <Typography variant="h4">Weekly Memory Verses</Typography>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <MemoryVerseSnippet
                memoryVerses={[
                  ...memoryVerses,
                  ...memoryVerses,
                  ...memoryVerses,
                ]}
              />
            </Grid>

            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" mb={4}>
                <Button
                  component={Link}
                  to="/memory-verses/plan/2025"
                  color="secondary"
                  endIcon={<ChevronRightIcon />}
                >
                  See 2025 Memory Verses
                </Button>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box display="flex" justifyContent="center">
                <Typography variant="h4">Articles</Typography>
              </Box>
            </Grid>

            {articles.map(({ id, title, author, date, imageThumb, slug }) => (
              <Grid item key={id} xs={12} md={4}>
                <CoverItem
                  title={title}
                  subtext={author.name}
                  timestamp={date}
                  image={imageThumb}
                  to={`/articles/${slug}`}
                />
              </Grid>
            ))}

            <Grid item xs={12}>
              <Box display="flex" justifyContent="center">
                <Button
                  component={Link}
                  to="/articles"
                  color="secondary"
                  endIcon={<ChevronRightIcon />}
                >
                  See all
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box
        py={10}
        style={{
          backgroundColor: theme.palette.background.secondary,
        }}
      >
        <Container maxWidth="lg">
          <Box textAlign="center" mb={6}>
            <Typography variant="h4">
              About River of Life Christian Fellowship
            </Typography>
          </Box>

          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Box mb={4}>
                <Box mb={4}>
                  <Typography variant="body1">
                    We are a growing body of Christian believers in Northern
                    Colorado seeking to fulfill God’s calling over our lives,
                    our homes, and our church by being a local expression of the
                    Body of Christ. RLCF is evangelical and unaffiliated with
                    any denomination, and is led by four elders:{' '}
                    <MuiLink component={Link} to="/speakers/ajay-chakravarthy">
                      Ajay Chakravarthy
                    </MuiLink>
                    ,{' '}
                    <MuiLink component={Link} to="/speakers/olu-talabi">
                      Olu Talabi
                    </MuiLink>
                    ,{' '}
                    <MuiLink component={Link} to="/speakers/santosh-poonen">
                      Santosh Poonen
                    </MuiLink>
                    , and{' '}
                    <MuiLink component={Link} to="/speakers/david-bertsch">
                      David Bertsch
                    </MuiLink>
                    .
                  </Typography>
                </Box>
                <Box textAlign="center">
                  <Button
                    component={Link}
                    to="/about"
                    disableElevation
                    endIcon={<ChevronRightIcon />}
                    color="secondary"
                  >
                    More about us
                  </Button>
                </Box>
              </Box>
            </Grid>

            <Grid item md={6} xs={12}>
              <Box mb={4}>
                <Box mb={4}>
                  <Typography variant="h4">Meeting Location</Typography>
                </Box>

                <SundayLocation />

                <Button
                  component={Link}
                  to="/events/sunday"
                  color="secondary"
                  endIcon={<ChevronRightIcon />}
                >
                  See meeting details
                </Button>
              </Box>
            </Grid>

            <Grid item md={6} xs={12}>
              <Box mb={4}>
                <Box mb={4}>
                  <Typography variant="h4">Contact Us</Typography>
                </Box>

                <Contact />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Footer />

      <Deploy />
    </>
  );
};

Page.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Page;
